import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { InputSize } from "@redotech/redo-web/input";
import {
  LabelOrientation,
  LabelPosition,
  LabelTheme,
} from "@redotech/redo-web/labeled-input";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import {
  FormTextInput,
  InputBoxHeight,
  InputLines,
} from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import classNames from "classnames";
import { memo, useContext, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../app/redo-admin-rpc-client-provider";
import { TeamContext } from "../../team";

export const conciergeForm = groupInput({
  placeFromThemeExtension: input<boolean>(),
  themeExtensionCustomCss: input<string>(),
});

export type ConciergeForm = InputProvider.Form<typeof conciergeForm>;

export type ConciergeValue = InputProvider.Value<typeof conciergeForm>;

export const conciergeDefault: ConciergeValue = {
  placeFromThemeExtension: false,
  themeExtensionCustomCss: "",
};

export const ConciergeCard = memo(function ConciergeCard({
  input,
}: {
  input: ConciergeForm;
}) {
  const { placeFromThemeExtension, themeExtensionCustomCss } = input.inputs;

  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);
  const team = useContext(TeamContext);

  const [syncStarted, setSyncStarted] = useState(false);
  const [_, doStartSync] = useTriggerLoad(async (signal) => {
    if (team) {
      setSyncStarted(true);
      await rpcClient.syncShopifyProductsForTeam(
        { teamId: team._id },
        { signal },
      );
    }
  });

  return (
    <Card title="Storefront AI Agent">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <div className={gridCss.grid}>
            <div className={classNames(gridCss.span6L, gridCss.span12)}>
              <FormSwitch
                input={placeFromThemeExtension}
                label="Place product form from theme extension"
                orientation={LabelOrientation.VERTICAL}
                position={LabelPosition.RIGHT}
              >
                This will place the Storefront AI Agent entry point on the PDP
                via the theme extension. For use in cases where the merchant's
                theme does not allow adding it via the App Block.
              </FormSwitch>
            </div>
          </div>
        </div>
        {placeFromThemeExtension.value && (
          <div className={gridCss.span12}>
            <div className={gridCss.grid}>
              <div className={classNames(gridCss.span6L, gridCss.span12)}>
                <FormTextInput
                  description="Add custom CSS rules to apply to the Storefront AI Agent Product Form. Example: padding-top: 16px; padding-bottom: 8px;"
                  input={themeExtensionCustomCss}
                  label="Custom CSS"
                  labelTheme={LabelTheme.THIN_BOLD}
                  lines={InputLines.MULTI}
                  size={InputSize.SMALL}
                  textAreaHeight={InputBoxHeight.MEDIUM}
                />
              </div>
            </div>
          </div>
        )}
        <div className={gridCss.span12}>
          <Flex dir="column">
            <Text>Sync Shopify products</Text>
            <Text fontSize="xs" textColor="tertiary">
              This will sync Shopify products for this team. This is already a
              process that happens via a cron job on a 30 minute schedule. The
              intention of this button is to allow for manual syncs.
            </Text>
            <Flex>
              <RedoButton
                disabled={syncStarted}
                hierarchy="primary"
                onClick={() => doStartSync()}
                text={syncStarted ? "Sync started" : "Sync Shopify products"}
              />
            </Flex>
          </Flex>
        </div>
      </section>
    </Card>
  );
});
