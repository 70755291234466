// extracted by mini-css-extract-plugin
export var brand = "_o-9";
export var button = "_o-0";
export var chevron = "_o-v";
export var chevronButton = "_o-w";
export var content = "_o-n";
export var danger = "_o-g";
export var dangerOutlined = "_o-h";
export var dark = "_o-l";
export var darkTheme = "_o-c";
export var extraSmall = "_o-4";
export var fullWidth = "_o-8";
export var ghost = "_o-d";
export var gray = "_o-t";
export var icon = "_o-p";
export var iconButton = "_o-s";
export var iconButtonBorder = "_o-x";
export var iconButtonBorderDark = "_o-y";
export var iconButtonBorderLight = "_o-z";
export var iconLeft = "_o-q";
export var iconRight = "_o-r";
export var large = "_o-1";
export var light = "_o-m";
export var medium = "_o-2";
export var micro = "_o-5";
export var nano = "_o-7";
export var outlined = "_o-k";
export var pending = "_o-a";
export var primary = "_o-i";
export var shadow = "_o-o";
export var small = "_o-3";
export var solidLight = "_o-e";
export var solidLightBrand = "_o-f";
export var spinner = "_o-u";
export var transparent = "_o-j";
export var warning = "_o-b";
export var wideNano = "_o-6";