// extracted by mini-css-extract-plugin
export var bubble = "_18-0";
export var container = "_18-3";
export var contrastBorder = "_18-4";
export var greyOut = "_18-2";
export var initialsWrapper = "_18-1";
export var large = "_18-9";
export var medium = "_18-8";
export var mediumSmall = "_18-7";
export var micro = "_18-5";
export var small = "_18-6";
export var tiny = "_18-b";
export var xTiny = "_18-a";