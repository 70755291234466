import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { checkAmpRegistrationStatus } from "./schema/amp/check-amp-registration-status";
import { getAmpRegistrationFormAnswers } from "./schema/amp/get-amp-registration-form-answers";
import { sendDemoAmpEmailsForTeam } from "./schema/amp/send-demo-amp-emails-for-team";
import { getAuditLog, getAuditLogs } from "./schema/audit-logs/audit-logs";
import { addBalanceCredit } from "./schema/balance/add-balance-credit";
import { getBalance } from "./schema/balance/get-balance";
import { getBalanceScheduleInfo } from "./schema/balance/get-balance-schedule-info";
import { upsertBalanceSchedule } from "./schema/balance/upsert-balance-schedule";
import { createCommentSoldTeam } from "./schema/comment-sold/create-team";
import { canStartHalfRedoCheckoutAbTest } from "./schema/conversion/can-start-half-redo-checkout-ab-test";
import { getCalculatedFulfillmentDelay } from "./schema/conversion/get-calculated-fulfillment-delay";
import { getCheckoutExperiences } from "./schema/conversion/get-checkout-experiences";
import { getConversionSettings } from "./schema/conversion/get-conversion-settings";
import { startHalfRedoCheckoutAbTest } from "./schema/conversion/start-half-redo-checkout-ab-test";
import { updateConversionSettings } from "./schema/conversion/update-conversion-settings";
import { getMultipassSecret } from "./schema/customer-accounts/get-multipass-secret";
import { setMultipassSecret } from "./schema/customer-accounts/set-multipass-secret";
import { getGmailPromotionTabWhitelistStatus } from "./schema/gmail-promotion-tab-whitelist/get-gmail-promotion-tab-whitelist-status";
import { sendGmailPromotionTabWhitelistRequest } from "./schema/gmail-promotion-tab-whitelist/send-gmail-promotion-tab-whitelist-request";
import { getBigcommerceIntegration } from "./schema/integrations/get-bigcommerce-integration";
import { getEmailTemplatesForTeam } from "./schema/marketing/get-email-templates-for-team";
import { sendTestEmail } from "./schema/marketing/send-test-email";
import { patchStorefrontTreatment } from "./schema/patch-admin-settings/patch-storefront-treatment-schema";
import { patchWidgetConfigs } from "./schema/patch-admin-settings/patch-widget-configs-schema";
import { createPhoneNumber } from "./schema/phone-numbers/create-phone-number";
import { getPhoneNumberByTeamIdAndUseCase } from "./schema/phone-numbers/get-phone-number-by-team-id-and-use-case";
import { updatePhoneNumber } from "./schema/phone-numbers/update-phone-number";
import { deleteRateTable } from "./schema/rate-tables/delete-rate-table";
import { getRateTables } from "./schema/rate-tables/get-rate-tables";
import { getShipmentRateDetails } from "./schema/rate-tables/get-shipment-rate-details";
import { saveRateTable } from "./schema/rate-tables/save-rate-table";
import { getRateTablesFromDeliveryProfile } from "./schema/redo-in-shipping/get-rate-tables-from-delivery-profile";
import { getRisIntegration } from "./schema/redo-in-shipping/get-ris-integration";
import { hasCarrierServices } from "./schema/redo-in-shipping/has-carrier-services";
import { updateRisIntegration } from "./schema/redo-in-shipping/update-ris-integration";
import { refreshBilling } from "./schema/refresh-billing";
import { getCheckoutOutboundRatesSettings } from "./schema/shopify/get-checkout-outbound-rates-settings";
import { getDeliveryProfile } from "./schema/shopify/get-delivery-profile";
import { requestShippingDeliveryPermissions } from "./schema/shopify/request-shipping-delivery-permissions";
import { syncShopifyProductsForTeam } from "./schema/shopify/sync-shopify-products-for-team";
import { updateCheckoutOutboundRatesSettings } from "./schema/shopify/update-checkout-outbound-rates-settings";
import { createSplit } from "./schema/splits/create-split";
import { createTreatment } from "./schema/splits/create-treatment";
import { getSplit } from "./schema/splits/get-split";
import { getTreatments } from "./schema/splits/get-treatments";
import { updateSplit } from "./schema/splits/update-split";
import { updateTreatment } from "./schema/splits/update-treatment";
import { getSupportAiPricing } from "./schema/support/get-support-ai-pricing";

const accounts = { getMultipassSecret, setMultipassSecret };

const conversion = {
  getConversionSettings,
  updateConversionSettings,
  getCheckoutExperiences,
  canStartHalfRedoCheckoutAbTest,
  startHalfRedoCheckoutAbTest,
};

const ris = {
  getRisIntegration,
  updateRisIntegration,
  getRateTablesFromDeliveryProfile,
  getRateTables,
  saveRateTable,
  deleteRateTable,
  getDeliveryProfile,
  hasCarrierServices,
};

const integrations = { getBigcommerceIntegration };

const amp = {
  sendDemoAmpEmailsForTeam,
  checkAmpRegistrationStatus,
  getAmpRegistrationFormAnswers,
};

const gmailPromotionTabWhitelist = {
  getGmailPromotionTabWhitelistStatus,
  sendGmailPromotionTabWhitelistRequest,
};

const admin = {
  getAuditLog,
  getAuditLogs,
  getSupportAiPricing,
  getShipmentRateDetails,
  getBalance,
  addBalanceCredit,
  syncShopifyProductsForTeam,
  getDeliveryProfile,
  requestShippingDeliveryPermissions,
  patchWidgetConfigs,
  patchStorefrontTreatment,
  refreshBilling,
  getEmailTemplatesForTeam,
  getBalanceScheduleInfo,
  upsertBalanceSchedule,
  createCommentSoldTeam,
  getPhoneNumberByTeamIdAndUseCase,
  createPhoneNumber,
  updatePhoneNumber,
  getCheckoutOutboundRatesSettings,
  updateCheckoutOutboundRatesSettings,
  getCalculatedFulfillmentDelay,
  createSplit,
  updateSplit,
  createTreatment,
  updateTreatment,
  getTreatments,
  getSplit,
  sendTestEmail,
};

type RpcDefinition = typeof accounts &
  typeof conversion &
  typeof ris &
  typeof amp &
  typeof gmailPromotionTabWhitelist &
  typeof integrations &
  typeof admin;

export const rpcDefinition: RpcDefinition = {
  ...accounts,
  ...conversion,
  ...ris,
  ...amp,
  ...gmailPromotionTabWhitelist,
  ...integrations,
  ...admin,
};

export type AdminRpcDefinition = InferRpcDefinition<typeof rpcDefinition>;
