import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { RegistrationRewardType } from "@redotech/redo-model/extended-warranty";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { groupInput, InputProvider } from "@redotech/ui/form";
import { memo, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RedoAdminClientContext } from "../../client/context";
import { getTeam, updateTeam } from "../../client/team";
import { updateExtendedWarrantySettings } from "../../client/warranties";
import { LoadTeamContext, TeamContext } from "../team";
import { ExperienceDesignCard } from "./warranties/experience-design";
import {
  ExtendedWarranties,
  extendedWarrantyFormValueToTeam,
  extendedWarrantySettingsDefault,
  extendedWarrantySettingsForm,
  extendedWarrantyTeamToFormValue,
} from "./warranties/extended-warranties";
import {
  Warranties,
  warrantiesForm,
  warrantiesDefault as warrantiesGeneralDefault,
} from "./warranties/general";

export const WarrantiesPage = memo(function WarrantiesPage() {
  const params = useParams();
  const teamId = params.teamId!;

  const team = useContext(TeamContext);
  const loadTeam = useRequiredContext(LoadTeamContext);

  const handleSave = useHandler(() => {
    loadTeam();
  });

  const value = useMemo(() => {
    if (!team) {
      return;
    }

    const extendedWarrantyFormValue = extendedWarrantyTeamToFormValue(team);

    return {
      warranties: {
        enabled: team.settings.warranties?.enabled ?? false,
        warrantyRegistrationCollection:
          team.settings.warranties?.warrantyRegistrationCollection,
        labelCoverageEnabled:
          team.settings.warranties?.labelCoverageEnabled ?? false,
        customersCanStartClaims:
          team.settings.warranties?.enabled &&
          team.settings.warranties?.customersCanStartClaims,
        registrationRewardType:
          team.settings.warranties?.registrationRewardType ??
          RegistrationRewardType.DISABLED,
        registrationEnabled:
          team.settings.warranties?.enabled &&
          team.settings.warranties?.registrationEnabled,
        registrationRewardDiscountId:
          team.settings.warranties?.registrationRewardDiscountId,
        repairFeeEnabled: team.settings.warranties?.repairFeeEnabled ?? false,
        defaultRepairMaxPrice: team.settings.warranties?.defaultRepairMaxPrice
          ? String(team.settings.warranties.defaultRepairMaxPrice)
          : "1",
        defaultRepairMinPrice: team.settings.warranties?.defaultRepairMinPrice
          ? String(team.settings.warranties.defaultRepairMinPrice)
          : "1",
        repairPricingCustomCollections:
          team.settings.warranties?.repairPricingRuleSet?.map(
            (rule) => rule.collectionHandle,
          ) || [],
        repairPricingRuleSet:
          team.settings.warranties?.repairPricingRuleSet?.map((rule) => ({
            minPrice: String(rule.minPrice),
            maxPrice: String(rule.maxPrice),
            collectionHandle: rule.collectionHandle,
            collectionTitle: rule.collectionTitle,
          })) || [],
      },
      extendedWarranties: extendedWarrantyFormValue,
    };
  }, [team]);

  return value ? (
    <WarrantiesForm id={teamId} onSave={handleSave} value={value} />
  ) : null;
});

const warrantiesPageForm = groupInput({
  warranties: warrantiesForm,
  extendedWarranties: extendedWarrantySettingsForm,
});

type WarrantiesValue = InputProvider.Value<typeof warrantiesPageForm>;

const warrantiesDefault: WarrantiesValue = {
  warranties: warrantiesGeneralDefault,
  extendedWarranties: extendedWarrantySettingsDefault,
};

const WarrantiesForm = memo(function WarrantiesForm({
  id,
  onSave,
  value,
}: {
  id: string;
  onSave: (value: WarrantiesValue) => void;
  value: WarrantiesValue | undefined;
}) {
  const client = useRequiredContext(RedoAdminClientContext);

  const input = useInput(warrantiesPageForm, value || warrantiesDefault);

  const { warranties, extendedWarranties } = input.inputs;

  const save: PageFormSave = useHandler(async (signal) => {
    const team = await getTeam(client, { teamId: id, signal });
    const value = input.value;

    team.settings.extendedWarranties = extendedWarrantyFormValueToTeam(
      value.extendedWarranties,
    );
    team.settings.warranties = {
      enabled: value.warranties.enabled,
      labelCoverageEnabled: value.warranties.labelCoverageEnabled,
      warrantyRegistrationCollection:
        value.warranties.warrantyRegistrationCollection,
      customersCanStartClaims:
        value.warranties.enabled && value.warranties.customersCanStartClaims,
      registrationEnabled:
        value.warranties.enabled && value.warranties.registrationEnabled,
      registrationRewardType: value.warranties.registrationRewardType,
      registrationRewardDiscountId:
        value.warranties.registrationRewardDiscountId,
      repairFeeEnabled: value.warranties.repairFeeEnabled,
      defaultRepairMaxPrice: Number(value.warranties.defaultRepairMaxPrice),
      defaultRepairMinPrice: Number(value.warranties.defaultRepairMinPrice),
      repairPricingRuleSet: value.warranties.repairPricingRuleSet.map(
        (rule) => ({
          minPrice: Number(rule.minPrice),
          maxPrice: Number(rule.maxPrice),
          collectionHandle: rule.collectionHandle,
          collectionTitle: rule.collectionTitle,
        }),
      ),
    };

    // TODO: Add sideImage and logo for extended warranties to admin dashboard
    if (input.inputs.extendedWarranties.changed) {
      await updateExtendedWarrantySettings(client, {
        teamId: id,
        extendedWarrantySettings: team.settings.extendedWarranties,
        // TODO: Add back in as dynamic resource
        sideImage:
          value.extendedWarranties.experienceDesign.sideImage?.toString(),
        logo: value.extendedWarranties.experienceDesign.logo?.toString(),
        signal,
      });
    }

    await updateTeam(client, { teamId: id, team, signal });

    onSave(value);
  });

  return (
    <PageForm initial={value || warrantiesDefault} input={input} save={save}>
      <div className={gridCss.grid}>
        <div className={gridCss.span6L}>
          <Warranties input={warranties} />
        </div>
        <div className={gridCss.span6L}>
          <ExtendedWarranties input={extendedWarranties} />
        </div>
        <div className={gridCss.span12}>
          <ExperienceDesignCard
            extendedWarrantySettings={extendedWarranties.value}
            input={extendedWarranties.inputs.experienceDesign}
          />
        </div>
      </div>
    </PageForm>
  );
});
