// extracted by mini-css-extract-plugin
export var brand = "_2c-7";
export var compact = "_2c-5";
export var extraSmall = "_2c-a";
export var icon = "_2c-0";
export var large = "_2c-3";
export var light = "_2c-1";
export var medium = "_2c-4";
export var outline = "_2c-9";
export var primaryDark = "_2c-8";
export var primaryLight = "_2c-6";
export var primaryModern = "_2c-b";
export var success = "_2c-2";