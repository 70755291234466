import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { memo } from "react";
import { OutboundLabelForm } from "../outbound-labels";

export const OutboundLabelsGeneral = memo(function OutboundLabelsGeneral({
  input,
}: {
  input: OutboundLabelForm;
}) {
  const { newOmsEnabled, automationDelaySeconds } = input.inputs;

  return (
    <Card title="General">
      <Flex dir="column" gap="md">
        <FormSwitch input={newOmsEnabled} label="Enable New OMS" />
        <FormTextInput
          description="Seconds to wait before running automations on new fulfillment groups. We will not get rates until after automations have completed."
          input={automationDelaySeconds}
          label="Automation Delay (seconds)"
          min={0}
          placeholder="0"
          step={1}
          type="number"
        />
      </Flex>
    </Card>
  );
});
