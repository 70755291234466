// extracted by mini-css-extract-plugin
export var button = "_h-0";
export var buttonContent = "_h-a";
export var dontRestrainIconWidth = "_h-7";
export var dropdownButton = "_h-8";
export var iconContainer = "_h-6";
export var lg = "_h-4";
export var md = "_h-3";
export var pending = "_h-b";
export var sm = "_h-2";
export var spinner = "_h-9";
export var xl = "_h-5";
export var xs = "_h-1";