// extracted by mini-css-extract-plugin
export var box = "_1y-0";
export var check = "_1y-1";
export var checkboxButton = "_1y-6";
export var checkboxGroup = "_1y-5";
export var disabled = "_1y-7";
export var ghost = "_1y-9";
export var input = "_1y-2";
export var label = "_1y-4";
export var normal = "_1y-8";
export var uncheck = "_1y-3";