// extracted by mini-css-extract-plugin
export var alternate = "_1i-h";
export var black = "_1i-3";
export var blue = "_1i-d";
export var blueLight = "_1i-c";
export var brand = "_1i-7";
export var error = "_1i-9";
export var gray = "_1i-6";
export var grayBlue = "_1i-b";
export var iconWrapper = "_1i-4";
export var indigo = "_1i-e";
export var orange = "_1i-g";
export var pink = "_1i-f";
export var primary = "_1i-0";
export var purple = "_1i-8";
export var success = "_1i-5";
export var warning = "_1i-a";
export var white = "_1i-1";
export var whiteSuccess = "_1i-2";