// extracted by mini-css-extract-plugin
export var disabled = "_g-6";
export var hierarchyLinkGray = "_g-a";
export var hierarchyLinkUnderline = "_g-9";
export var hierarchyPrimary = "_g-0";
export var hierarchySecondary = "_g-7";
export var hierarchyTertiary = "_g-8";
export var pressed = "_g-2";
export var themeAction = "_g-1";
export var themeDestructive = "_g-4";
export var themeNormal = "_g-3";
export var themeSuccess = "_g-5";