// extracted by mini-css-extract-plugin
export var chip = "_1v-9";
export var chipLabel = "_1v-a";
export var close = "_1v-7";
export var closeIcon = "_1v-8";
export var error = "_1v-6";
export var infoTooltip = "_1v-2";
export var innerContainer = "_1v-1";
export var input = "_1v-b";
export var medium = "_1v-5";
export var outerContainer = "_1v-0";
export var small = "_1v-4";
export var xSmall = "_1v-3";