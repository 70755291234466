import { VariantResource, Variants } from "./resource";

export const COMMON: VariantResource<Variants> = {
  DOCUMENT_TITLE: "Returns & Exchanges",
  TYPE_DISPLAY_NAME: "return",
  ORDERLESS_DISPLAY_NAME: "Third-party",
  LOGIN_FIELD_ONE_LABEL: "Email or Zip/Postal",
  LOGIN_FIELD_TWO_LABEL: "Order # / Tracking #",
  PORTAL_BUTTON_TEXT: "Start a return",
  PORTAL_GIFT_BUTTON_TEXT: "Returning a gift?",
  PORTAL_POLICY_LINK_TEXT: "Return Policy",
  PORTAL_WARRANTY_LINK_TEXT: "Register a product or file a warranty claim",
  ITEMS_OLD_HEADER: "What you're returning",
  ITEMS_NEW_HEADER: "Your new items",
  ITEMS_DISCLAIMER:
    "The amounts listed below are estimates and may change upon processing if packages have been used or open.",
  THIRD_PARTY_ITEMS_HEADER: "Select items",
  THIRD_PARTY_ITEMS_SUBHEADER:
    "Choose the items that you wish to report a claim on.",
  THIRD_PARTY_DOCUMENT_TITLE: "Third-party Claim",
  THIRD_PARTY_HEADER: "",
  IN_STORE_RETURN_TEXT: "Return to store",
  INSTANT_EXCHANGE_DISCLAIMER_SUBTEXT: "",
  FEES_HEADER: "Return value",
  FEES_ORDER_HEADER: "New order",
  FREE_SHIPPING_LABEL_TEXT: "Free shipping label covered by",
  FREE_SHIPPING_LABEL_TEXT_NO_BRANDING: "Free shipping label",
  REVIEW_SUBMIT_BUTTON_TEXT: "Submit return",
  REVIEW_SHIPPING_FROM_TEXT: "Returning items from",
  REVIEW_SHIPPING_TO_SAME_TEXT: "Same as returning items from",
  REVIEW_LABEL_COVERAGE: "Return covered for free by",
  REVIEW_FLOW_HEADER: "Review your return",
  STATUS_STEPS_TITLE: "What happens next?",
  STATUS_MANUAL_REVIEW_TITLE: "We'll review your return request",
  STATUS_MANUAL_REVIEW_SUBTITLE:
    "We'll email you the return label if your return is approved",
  STATUS_IN_TRANSIT_TITLE: "Pack up your returns",
  STATUS_IN_TRANSIT_SUBTITLE: "Print your shipping label",
  STATUS_IN_TRANSIT_QR_CODE: "(or open the QR code)",
  STATUS_IN_TRANSIT_MULTI_SHIPMENT_SUBTITLE:
    "Look below for all of your shipping labels",
  STATUS_PROCESSED_TITLE: "Carrier delivers the return package to us",
  STATUS_PROCESSED_SUBTITLE: "We'll review and process your return",
  STATUS_SHIPPING_TEXT:
    "Please confirm the address you'd like your new order shipped to.",
  STATUS_DROP_OFF_SUBTITLE: "",
  EMAIL_SUBJECT: "We received your return request",
  EMAIL_HEADING: "Thanks for your return request.",
  EMAIL_BODY:
    "Please follow the steps below so that we can process your return as quickly as possible.",
  EMAIL_SHIPMENT_LABEL: "Items to ship back:",
  EMAIL_SHIPMENT_TITLE: "Shipment",
  EMAIL_SHIPMENT_URL: "shipping label(s)",
  EMAIL_STEP_1: "Package all items you're requesting to be returned.",
  EMAIL_REVIEW_SUBJECT: "A new return requires a review",
  EMAIL_REVIEW_BODY_1: "A customer created a return",
  EMAIL_CREATED_SUBJECT: "A return has been created",
  EMAIL_CREATED_HEADING: "A new return has been created",
  EMAIL_CREATED_BODY_1: "A customer created a return",
  IN_STORE_RETURN_EMAIL_STEP_1:
    "Gather all items you're requesting to be returned.",
  SHOPIFY_NOTE: "This is an exchange order generated from Redo, for order",
  REVIEW_HEADER_TEXT: "Review your return",
  CHOOSE_ITEMS_TEXT: "Choose items you're returning",
  CHOOSE_VARIANT_TEXT: "Select the type that you're returning",
  PENDING_ITEM_PILL_TEXT: "Ready for return",
  CONFIRMATION_HEADER_TEXT_SUBMIT: "Your return has been submitted",
  CONFIRMATION_HEADER_TEXT_SUBMIT_WITH_ERRORS:
    "There were problems submitting your return",
  CONFIRMATION_HEADER_TEXT_REJECT: "Your return has been rejected",
  GREEN_RETURN_PROCESS_STEP_TEXT: "We process the return",
  PORTAL_ORDERS_TABLE_RETURNS_HEADER: "Returns / Claims",
  PORTAL_ORDER_TABLE_RETURN_LINK_TEXT: "View return",
  ORDER_DETAILS_LIST_TITLE_TEXT: "Returns",
  ORDER_DETAILS_LIST_ITEM_TEXT: "Return",
  ADDRESS_PICKUP_UNAVAILABLE: "Pickup is not available at this address",

  PICKUP_MODAL_MAIN_TEXT: "Leave it on your doorstep. We'll handle the rest.",
  PICKUP_MODAL_SUB_TEXT:
    "Pack up your return and leave it at your door. We'll take care of it for only %price%.",
  PICKUP_MODAL_PICKUP_BUTTON_TEXT: "Schedule package pickup",
  PICKUP_MODAL_SHIPPING_BUTTON_TEXT: "Ship back to us",
  variants: {
    claim: {
      TYPE_DISPLAY_NAME: "claim",
      PORTAL_BUTTON_TEXT: "Start a claim",
      PORTAL_GIFT_BUTTON_TEXT: "Start a claim for a gift",
      PORTAL_POLICY_LINK_TEXT: "Claim Policy",
      ITEMS_OLD_HEADER: "What you're filing a claim for",
      ITEMS_NEW_HEADER: "Your replacement items",
      FEES_HEADER: "Claim value",
      FEES_ORDER_HEADER: "Replacement order",
      REVIEW_SUBMIT_BUTTON_TEXT: "Submit claim",
      REVIEW_SHIPPING_FROM_TEXT: "Shipping from",
      REVIEW_SHIPPING_TO_SAME_TEXT: "Same as shipping from",
      REVIEW_LABEL_COVERAGE: "Claim covered for free by",
      REVIEW_FLOW_HEADER: "Review your shipping claim",
      STATUS_MANUAL_REVIEW_TITLE: "We'll review your claim request",
      STATUS_MANUAL_REVIEW_SUBTITLE:
        "We'll email you a label for your items if your claim is approved",
      STATUS_PROCESSED_TITLE: "Carrier delivers the package to us",
      STATUS_PROCESSED_SUBTITLE: "We'll review and process your claim",
      STATUS_SHIPPING_TEXT:
        "Please confirm the address you'd like your replacement items shipped to.",
      EMAIL_SUBJECT: "We received your claim request",
      EMAIL_HEADING: "Thanks for your claim request.",
      EMAIL_BODY:
        "Please follow the steps below so that we can process your claim as quickly as possible.",
      EMAIL_STEP_1: "Package all items you're filing a claim for.",
      EMAIL_REVIEW_SUBJECT: "A new claim requires a review",
      EMAIL_REVIEW_BODY_1: "A customer created a package protection claim",
      EMAIL_CREATED_SUBJECT: "A claim has been created",
      EMAIL_CREATED_HEADING: "A new claim has been created",
      EMAIL_CREATED_BODY_1: "A customer created a claim",
      IN_STORE_RETURN_EMAIL_STEP_1:
        "Gather all items you're filing a claim for.",
      SHOPIFY_NOTE:
        "This is a replacement order generated from Redo, for order",
      REVIEW_HEADER_TEXT: "Submitting claim",
      CHOOSE_ITEMS_TEXT: "Choose items",
      CONFIRMATION_HEADER_TEXT_SUBMIT: "Your claim has been submitted",
      CONFIRMATION_HEADER_TEXT_SUBMIT_WITH_ERRORS:
        "There were problems submitting your claim",
      CONFIRMATION_HEADER_TEXT_REJECT: "Your claim has been rejected",
      PENDING_ITEM_PILL_TEXT: "Ready for claim",
      PORTAL_ORDER_TABLE_RETURN_LINK_TEXT: "View claim",
      ORDER_DETAILS_LIST_ITEM_TEXT: "Claim",
      ORDER_DETAILS_LIST_TITLE_TEXT: "Claims",
      GREEN_RETURN_PROCESS_STEP_TEXT: "We process the claim",
    },
    exchange: {
      PORTAL_BUTTON_TEXT: "Start exchange or return",
      EMAIL_SUBJECT: "We received your return or exchange request",
      EMAIL_BODY:
        "Package all items you're requesting to be returned or exchanged.",
      EMAIL_STEP_1:
        "Package all items you're requesting to be returned or exchanged.",
      IN_STORE_RETURN_EMAIL_STEP_1:
        "Gather all items you're requesting to be returned or exchanged.",
    },
    return_claim: {
      PORTAL_BUTTON_TEXT: "Start return or claim",
      PORTAL_GIFT_BUTTON_TEXT: "Start a return or claim for a gift",
      PORTAL_POLICY_LINK_TEXT: "Return/Claim Policy",
    },
    pickup: {
      REVIEW_SHIPPING_FROM_TEXT: "Pickup Address",
      STATUS_IN_TRANSIT_SUBTITLE: "Use original packaging or poly bag",
      STATUS_PROCESSED_TITLE: "Package Pickup",
      STATUS_PROCESSED_SUBTITLE:
        "Carrier will pickup the package from the pickup address and deliver the return to us. We'll review and process your return",
      EMAIL_HEADING: "Thank you for your pickup request on your return order",
    },
    // TODO: Separate warranty and repair language
    warranty: {
      TYPE_DISPLAY_NAME: "warranty",
      PORTAL_BUTTON_TEXT: "File a warranty claim",
      ITEMS_OLD_HEADER: "Items you’re submitting",
      REVIEW_SUBMIT_BUTTON_TEXT: "Submit warranty claim request",
      REVIEW_SHIPPING_FROM_TEXT: "Sending items from",
      REVIEW_FLOW_HEADER: "Review your warranty claim request",
      REVIEW_HEADER_TEXT: "Review your warranty claim request",
      PENDING_ITEM_PILL_TEXT: "Ready for claim",
      STATUS_MANUAL_REVIEW_TITLE: "We'll review your warranty claim request",
      STATUS_MANUAL_REVIEW_SUBTITLE:
        "We'll email you the label if your warranty claim is approved",
      STATUS_IN_TRANSIT_TITLE: "Pack up your items",
      STATUS_PROCESSED_TITLE: "Carrier delivers the package to us",
      STATUS_PROCESSED_SUBTITLE:
        "We'll review and process your warranty claim request",
      EMAIL_SUBJECT: "We received your warranty claim request",
      EMAIL_HEADING: "Thanks for your warranty claim request.",
      EMAIL_BODY:
        "Please follow the steps below so that we can process your warranty claim as quickly as possible.",
      EMAIL_STEP_1: "Package all items you're requesting to be processed.",
      EMAIL_REVIEW_SUBJECT: "A new warranty claim requires a review",
      EMAIL_REVIEW_BODY_1: "A customer created a warranty claim",
      EMAIL_CREATED_SUBJECT: "A warranty claim has been created",
      EMAIL_CREATED_HEADING: "A new warranty claim has been created",
      EMAIL_CREATED_BODY_1: "A customer created a warranty claim",
      IN_STORE_RETURN_EMAIL_STEP_1:
        "Gather all items you're requesting to be processed.",
      CHOOSE_ITEMS_TEXT: "Choose items",
      CHOOSE_VARIANT_TEXT: "Select the type that you're submitting",
      // LOGIN_FIELD_TWO_LABEL: "Order # / Tracking # / Warranty Registration #",
      LOGIN_FIELD_TWO_LABEL: "Order # / Tracking #",
    },
    repair: {
      EMAIL_REVIEW_SUBJECT: "A new repair request requires a review",
      EMAIL_REVIEW_BODY_1: "A customer created a repair request",
      EMAIL_CREATED_SUBJECT: "A repair request has been created",
      EMAIL_CREATED_HEADING: "A new repair request has been created",
      EMAIL_CREATED_BODY_1: "A customer created a repair request",
    },
    "third-party": {
      SHOPIFY_NOTE:
        "This is an exchange order generated from Redo, for warranty registration",
    },
  },
};

// An example of a region override
// export const EN_US: VariantResource<Variants> = {
//   ...COMMON,
//   STATUS_PROCESSED_TITLE: "TEST ONE",
//   variants: {
//     ...COMMON.variants,
//     claim: {
//       ...COMMON.variants?.claim,
//       STATUS_PROCESSED_TITLE: "TEST TWO",
//     },
//   }
// };

export const EN_US: VariantResource<Variants> = { ...COMMON };

export const EN_AU: VariantResource<Variants> = { ...COMMON };
