// extracted by mini-css-extract-plugin
export var box = "_2a-1";
export var checked = "_2a-5";
export var descriptionSpacer = "_2a-9";
export var disabled = "_2a-4";
export var icon = "_2a-6";
export var input = "_2a-0";
export var labelSpacer = "_2a-8";
export var medium = "_2a-3";
export var small = "_2a-2";
export var text = "_2a-7";