// extracted by mini-css-extract-plugin
export var addButton = "_1z-d";
export var bullets = "_1z-e";
export var compact = "_1z-2";
export var container = "_1z-j";
export var extendedWarrantyModal = "_1z-f";
export var learn = "_1z-l";
export var learnMore = "_1z-g";
export var offeringOption = "_1z-3";
export var offeringPrice = "_1z-8";
export var offeringSubtitle = "_1z-7";
export var offeringTitle = "_1z-6";
export var offeringsContainer = "_1z-0";
export var pdpTilesBackground = "_1z-n";
export var plan = "_1z-m";
export var regular = "_1z-1";
export var remove = "_1z-k";
export var seeDetailsIcon = "_1z-i";
export var seeDetailsLink = "_1z-h";
export var selectedOption = "_1z-4";
export var shieldIcon = "_1z-b";
export var shieldIconCompact = "_1z-c";
export var subtitle = "_1z-a";
export var title = "_1z-9";
export var unselectedOption = "_1z-5";