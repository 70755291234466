// extracted by mini-css-extract-plugin
export var disabled = "_1b-4";
export var error = "_1b-1";
export var iconWrapper = "_1b-8";
export var infoIconWrapper = "_1b-a _1b-8";
export var input = "_1b-7";
export var inputWrapper = "_1b-0";
export var large = "_1b-6";
export var leadingIconWrapper = "_1b-9 _1b-8";
export var measureBox = "_1b-b";
export var medium = "_1b-5";
export var readonly = "_1b-2";
export var small = "_1b-3";