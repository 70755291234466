// extracted by mini-css-extract-plugin
export var grid = "_b-0";
export var span1 = "_b-2";
export var span10 = "_b-b";
export var span10L = "_b-z";
export var span10M = "_b-n";
export var span10Xl = "_b-1b";
export var span11 = "_b-c";
export var span11L = "_b-10";
export var span11M = "_b-o";
export var span11Xl = "_b-1c";
export var span12 = "_b-d";
export var span12L = "_b-11";
export var span12M = "_b-p";
export var span12Xl = "_b-1d";
export var span1L = "_b-q";
export var span1M = "_b-e";
export var span1Xl = "_b-12";
export var span2 = "_b-3";
export var span2L = "_b-r";
export var span2M = "_b-f";
export var span2Xl = "_b-13";
export var span3 = "_b-4";
export var span3L = "_b-s";
export var span3M = "_b-g";
export var span3Xl = "_b-14";
export var span4 = "_b-5";
export var span4L = "_b-t";
export var span4M = "_b-h";
export var span4Xl = "_b-15";
export var span5 = "_b-6";
export var span5L = "_b-u";
export var span5M = "_b-i";
export var span5Xl = "_b-16";
export var span6 = "_b-7";
export var span6L = "_b-v";
export var span6M = "_b-j";
export var span6Xl = "_b-17";
export var span7 = "_b-8";
export var span7L = "_b-w";
export var span7M = "_b-k";
export var span7Xl = "_b-18";
export var span8 = "_b-9";
export var span8L = "_b-x";
export var span8M = "_b-l";
export var span8Xl = "_b-19";
export var span9 = "_b-a";
export var span9L = "_b-y";
export var span9M = "_b-m";
export var span9Xl = "_b-1a";
export var stretch = "_b-1";