// extracted by mini-css-extract-plugin
export var chevron = "_1q-b";
export var disabled = "_1q-a";
export var hideBorder = "_1q-1";
export var icon = "_1q-c";
export var input = "_1q-0";
export var open = "_1q-8";
export var placeholder = "_1q-9";
export var regular = "_1q-4";
export var singleSelectInput = "_1q-2 _1q-0";
export var small = "_1q-3";
export var spinnerContainer = "_1q-e";
export var tag = "_1q-6";
export var tagsContainer = "_1q-7";
export var tagsInput = "_1q-5 _1q-0";
export var xSmall = "_1q-d";