// extracted by mini-css-extract-plugin
export var actions = "_j-0";
export var actionsContainer = "_j-4";
export var border = "_j-1";
export var childFullHeight = "_j-b";
export var collapse = "_j-2";
export var content = "_j-6";
export var contentChild = "_j-a";
export var fullscreen = "_j-8";
export var hidePadding = "_j-7";
export var noScrollX = "_j-c";
export var noScrollY = "_j-d";
export var optOutTopPadding = "_j-9";
export var pagePadding = "_j-5";
export var profile = "_j-e";
export var profileContainer = "_j-f";
export var profileImage = "_j-g";
export var profileMain = "_j-h";
export var profileSubtitle = "_j-j";
export var profileTitle = "_j-i";
export var tabsWrapper = "_j-3";
export var title = "_j-k";