import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { TextMessagingBillingDefault } from "@redotech/redo-model/support/billing/text-message-billing";
import { CloseActionMethod, Team } from "@redotech/redo-model/team";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { groupInput, InputProvider } from "@redotech/ui/form";
import classNames from "classnames";
import { memo, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RedoAdminRpcClientContext } from "../../app/redo-admin-rpc-client-provider";
import { RedoAdminClientContext } from "../../client/context";
import { getTeam, updateTeam } from "../../client/team";
import { LoadTeamContext, TeamContext } from "../team";
import * as teamCss from "../team.module.css";
import { SupportAiCard, supportAiDefault, supportAiForm } from "./support/ai";
import {
  ConciergeCard,
  conciergeDefault,
  conciergeForm,
} from "./support/concierge";
import { CustomerWidgetScriptTag } from "./support/customer-widget-script-tag";
import {
  SupportGeneralCard,
  supportGeneralDefault,
  supportGeneralForm,
} from "./support/general";
import { ImportReamazeMacrosCard } from "./support/import-reamaze-macros-card";
import { ImportZendeskMacrosCard } from "./support/import-zendesk-macros-card";
import { SupportOnboarding } from "./support/support-onboarding";
import {
  supportTextMessagingDefault,
  supportTextMessagingForm,
  TextMessaging,
} from "./support/text-messaging";

export const SupportPage = memo(function SupportPage() {
  const params = useParams();
  const teamId = params.teamId!;

  const team = useContext(TeamContext);
  const loadTeam = useRequiredContext(LoadTeamContext);

  const handleSave = useHandler(() => {
    loadTeam();
  });

  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);

  const pricingLoad = useLoad(async () => {
    const pricing = await rpcClient.getSupportAiPricing({ teamId });
    return pricing;
  }, [
    team?.settings.support?.billing?.base?.pricePerMonthInCents,
    team?.settings.support?.billing?.ai?.pricePerMonth,
  ]);

  const value: SupportValue | undefined = useMemo(() => {
    if (!team || !pricingLoad.value) {
      return;
    }
    const billingSetting = team.settings?.support?.billing;
    const aiBilling = billingSetting?.ai || {};
    return {
      general: {
        enabled: !!team.settings?.support?.enabled,
        pricePerMonth: (pricingLoad.value.platformPrice / 100).toString(),
        freeTrialEndDate: team.settings.support?.billing?.base?.freeTrialEndDate
          ? new Date(team.settings.support.billing.base.freeTrialEndDate)
          : null,
      },
      ai: {
        enabled: !!team.settings?.support?.ai?.enabled,
        aiPricePerMonth: (pricingLoad.value.aiPrice / 100).toString(),
        aiResolutionPrice: aiBilling?.aiResolutionPriceInCents
          ? (aiBilling.aiResolutionPriceInCents / 100).toString()
          : "0",
        aiTicketsCovered: aiBilling?.ticketsCovered?.toString() || "0",
        aiFreeTrialDays: aiBilling?.freeTrialDays?.toString() || "0",
        freeTrialUsed: aiBilling?.freeTrialUsed || false,
        lastDayActive: aiBilling?.lastDayActive
          ? new Date(aiBilling.lastDayActive)
          : null,
      },
      textMessaging: {
        enabled: !!billingSetting?.textMessaging?.enabled,
        pricePerSMSInTenthCents: (
          (billingSetting?.textMessaging?.pricePerSMSInTenthCents || 0) / 1000
        ).toString(),
        pricePerMMSInTenthCents: (
          (billingSetting?.textMessaging?.pricePerMMSInTenthCents || 0) / 1000
        ).toString(),
        monthlyMinimumInTenthCents: (
          (billingSetting?.textMessaging?.monthlyMinimumInTenthCents || 0) /
          1000
        ).toString(),
      },
      concierge: {
        placeFromThemeExtension:
          !!team.settings.concierge?.placeProductFormFromThemeExtension,
        themeExtensionCustomCss:
          team.settings.concierge?.themeExtensionProductFormCustomCss || "",
      },
    };
  }, [team, pricingLoad.value]);

  if (!pricingLoad.value) {
    return;
  }
  return (
    <SupportForm
      id={teamId}
      key={+!team}
      onSave={handleSave}
      team={team}
      value={value}
    />
  );
});

const supportForm = groupInput({
  general: supportGeneralForm,
  ai: supportAiForm,
  textMessaging: supportTextMessagingForm,
  concierge: conciergeForm,
});

export type SupportForm = InputProvider.Form<typeof supportForm>;

export type SupportValue = InputProvider.Value<typeof supportForm>;

const supportDefault: SupportValue = {
  general: supportGeneralDefault,
  ai: supportAiDefault,
  textMessaging: supportTextMessagingDefault,
  concierge: conciergeDefault,
};

const SupportForm = memo(function SupportForm({
  onSave,
  id,
  team,
  value,
}: {
  id: string;
  value: SupportValue | undefined;
  team: Team | undefined;
  onSave?: (value: SupportValue) => void;
}) {
  const client = useRequiredContext(RedoAdminClientContext);
  const input: SupportForm = useInput(supportForm, value || supportDefault);

  const save: PageFormSave = useHandler(async (signal) => {
    const team = await getTeam(client, { teamId: id, signal });

    const value = input.value;

    team.settings.support = team.settings.support || {
      enabled: !!value.general.enabled,
      ticketAssignment: "manual",
      usersForAutoAssignment: [],
      maxTicketsPerUser: "10",
      closeAction: CloseActionMethod.NEXT,
      undoSend: true,
    };

    team.settings.support.enabled = !!value.general.enabled;

    if (team.settings.support.ai) {
      team.settings.support.ai.enabled = !!value.ai.enabled;
    } else {
      team.settings.support.ai = {
        enabled: !!value.ai.enabled,
        copilotEnabled: false,
        autoGenerateResponses: true,
        hasTriedAi: false,
      };
    }

    const billing = team.settings.support.billing || {};

    let aiBilling = billing.ai || {};

    if (
      (input.inputs.ai.inputs.aiResolutionPrice.changed ||
        input.inputs.ai.inputs.aiPricePerMonth.changed ||
        input.inputs.ai.inputs.aiTicketsCovered.changed ||
        input.inputs.ai.inputs.lastDayActive.changed ||
        input.inputs.ai.inputs.freeTrialUsed.changed) &&
      value.ai.aiResolutionPrice &&
      value.ai.aiPricePerMonth &&
      value.ai.aiTicketsCovered
    ) {
      aiBilling = {
        ...aiBilling,
        freeTrialDays: Number(value.ai.aiFreeTrialDays),
        freeTrialUsed: value.ai.freeTrialUsed || false,
        pricePerMonth: Number(value.ai.aiPricePerMonth) * 100, //store in cents
        aiResolutionPriceInCents: Number(value.ai.aiResolutionPrice) * 100,
        ticketsCovered: Number(value.ai.aiTicketsCovered),
        planName: "Custom",
        atShopifyCap: false,
        ticketsUsed: aiBilling.ticketsUsed || 0,
        lastDayActive: value.ai.lastDayActive?.toISOString() || undefined,
      };
    }
    if (value.ai.aiFreeTrialDays) {
      aiBilling.freeTrialDays = Number(value.ai.aiFreeTrialDays);
    }

    team.settings.concierge = {
      ...team.settings.concierge,
      placeProductFormFromThemeExtension:
        value.concierge.placeFromThemeExtension,
      themeExtensionProductFormCustomCss:
        value.concierge.themeExtensionCustomCss || undefined,
    };

    let baseBilling = billing.base || {
      pricePerMonth: 0,
      subscriptionActive: false,
    };

    if (
      input.inputs.general.inputs.enabled.changed ||
      input.inputs.general.inputs.pricePerMonth.changed ||
      input.inputs.general.inputs.freeTrialEndDate.changed
    ) {
      baseBilling = {
        ...baseBilling,
        pricePerMonthInCents: Number(value.general.pricePerMonth) * 100,
        freeTrialEndDate: value.general.freeTrialEndDate
          ? value.general.freeTrialEndDate
          : undefined,
      };
    }

    if (!billing.textMessaging) {
      billing.textMessaging = { ...TextMessagingBillingDefault };
    }

    billing.textMessaging.enabled = value.textMessaging.enabled;
    billing.textMessaging.pricePerSMSInTenthCents =
      Number(value.textMessaging.pricePerSMSInTenthCents) * 1000;
    billing.textMessaging.pricePerMMSInTenthCents =
      Number(value.textMessaging.pricePerMMSInTenthCents) * 1000;
    billing.textMessaging.monthlyMinimumInTenthCents =
      Number(value.textMessaging.monthlyMinimumInTenthCents) * 1000;

    billing.ai = aiBilling;
    billing.base = baseBilling;
    team.settings.support.billing = billing;

    await updateTeam(client, { teamId: id, team, signal });
    onSave && onSave(value);
  });

  const { general, ai, textMessaging, concierge } = input.inputs;

  return (
    <div className={teamCss.container}>
      <PageForm initial={value || supportDefault} input={input} save={save}>
        <div className={gridCss.grid}>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <SupportGeneralCard input={general} />
              </div>
              <div className={gridCss.span12}>
                <SupportOnboarding />
              </div>
              <div className={gridCss.span12}>
                <TextMessaging input={textMessaging} />
              </div>
              <div className={gridCss.span12}>
                <ImportZendeskMacrosCard client={client} teamId={team?._id} />
              </div>
              <div className={gridCss.span12}>
                <ImportReamazeMacrosCard client={client} teamId={team?._id} />
              </div>
            </div>
          </div>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <SupportAiCard
                  input={ai}
                  shopifySubscription={team?.settings.shopifySubscription}
                />
              </div>
              <div className={gridCss.span12}>
                <ConciergeCard input={concierge} />
              </div>
              <div className={gridCss.span12}>
                <CustomerWidgetScriptTag />
              </div>
            </div>
          </div>
        </div>
      </PageForm>
    </div>
  );
});
