// extracted by mini-css-extract-plugin
export var border = "_25-5";
export var button = "_25-g";
export var center = "_25-7";
export var centered = "_25-3";
export var closeButton = "_25-9";
export var disabledWrapper = "_25-h";
export var error = "_25-k";
export var fill = "_25-d";
export var footer = "_25-c";
export var footerAction = "_25-e";
export var footerButtons = "_25-f";
export var header = "_25-4";
export var icon = "_25-j";
export var info = "_25-n";
export var inline = "_25-6";
export var large = "_25-2";
export var main = "_25-a";
export var modal = "_25-0";
export var neutral = "_25-o";
export var none = "_25-p";
export var scrollable = "_25-b";
export var small = "_25-1";
export var success = "_25-m";
export var tight = "_25-i";
export var titleIcon = "_25-8";
export var warn = "_25-l";