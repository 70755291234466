// extracted by mini-css-extract-plugin
export var active = "_21-a";
export var activeIndicator = "_21-9";
export var disabled = "_21-b";
export var fullWidth = "_21-2";
export var lg = "_21-7";
export var md = "_21-6";
export var redoButtonTabGroup = "_21-0";
export var sm = "_21-5";
export var tabButton = "_21-4";
export var tabButtonsContainer = "_21-1";
export var themeDestructive = "_21-d";
export var themeNormal = "_21-c";
export var themeSuccess = "_21-e";
export var xl = "_21-8";
export var xs = "_21-3";