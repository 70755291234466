// extracted by mini-css-extract-plugin
export var checkbox = "_1d-0";
export var circle = "_1d-3";
export var horizontalContainer = "_1d-7";
export var sm = "_1d-5";
export var switch0 = "_1d-2";
export var switchDisabled = "_1d-8";
export var switchEnabled = "_1d-1";
export var textSwitchContainer = "_1d-9";
export var textSwitchOption = "_1d-a";
export var textSwitchOptionSelected = "_1d-b";
export var verticalContainer = "_1d-6";
export var xs = "_1d-4";